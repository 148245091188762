<template>
    <div>
        <AboutNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>Core Values</h1>

            <p><b>Fast</b></p>
            <p>Respond quickly</p>

            <p><b>Easy</b></p>
            <p>Don't complicate things</p>

            <p><b>Fun</b></p>
            <p>Be a great day maker!</p>

            <h3>Who we are and what we do</h3>

            <p>We say how we CAN not why we can't</p>
            <p>We are intentionally different</p>
            <p>We commit to doing better</p>
            <p>We Deliver as promised</p>

            <h3>Three Uniques</h3>

            <p><b>Speed</b></p>
            <p>No one can build and deliver faster.</p>

            <p><b>Technology</b></p>
            <p>Our technology makes your job easier and faster - you will sell more RTU's using our technology.</p>

            <p><b>Service</b></p>
            <p>We make it easy and fun!</p>
        </main>
    </div>
</template>

<script>
import AboutNav from '@/components/content/AboutNav.vue'
export default {
    name: "About",
    components: {
        AboutNav,
    },
    created() {
        this.$appStore.setTitle(["CDI's Core Values"]);
    }
}
</script>

<style scoped>

</style>